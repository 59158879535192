.header-indicator {
  width: 100%;
  background-color: #fafafa;
  border-top: 2px solid silver;
}

.header-indicator div[class$="active"] {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  top: 10px;
  position: fixed;
  left: 10px;
  display: inline-block;
}

.header-summary {
  width: 100%;
  height: 9.6rem;
  padding-top: 2.5rem;
  background-color: #fafafa;
  border-top: 2px solid #e7e7e7;
  border-bottom: 1px solid rgba(236, 238, 239, 0.77);
}

.header-search-form {
  display: inline-block;
  margin-left: 40px;
  position: absolute;
}

.header-search-form input {
  background-color: #fafafa;
  border: none;
  margin: 0;
  font-size: 16px;
  color: #a2a2a2;
}

.hearder-inicator-label {
  font-size: 16px;
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 3.2rem;
  text-align: center;
  top: 10px;
  font-weight: 500;
}