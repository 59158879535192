.nav-bar-icon a {
    height: 100%;
    width: 100%;
    display: block;
}

.nav-bar-icon a div {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
}

.navbar {
    padding: 0.6rem;
    background-color: #fafafa;
}

/** NO-ACTIVE COLOR : #c7c7c7 */
.rental-icon {
    height: 18px;
    width: 18px;
    margin-top: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAEYUlEQVRoQ+1ZS1LbQBDtRq5CWiU5QZITBG8oRpuQEwAniDlB4AQxJ4g5AXCCwAlCNhqKjcMJkpwgZIWoAib1VDMqWZ7RSLYHKIJWLkse9evX/fpjpidy8RPBQUGBnJ+fr9zc3Bww82UURburq6s/QjkuGBAp5WciGhrDlVKXS0tLw7W1tf0QYBYOpMLCisPgUyLaFkL8WiSghQKps+AyNAQ7CwEipXxDRAdEtN7Ry6dxHG/1+/3Ljr+benxuIGdnZ5/u7u6GzPzSYcx3/f17232wg1BL0/R4HjAzA2nBwl+l1DBN0xEMzLJsh5mR/C8cgI6TJNmelZ2ZgEgpB0qpLw0sXERRNKjLLYTg9vb2kIjeLZqdTkDG4/HLq6sr1IXNhjDYE0IUsmsUDJ97vd62ASalxH3Is+s6jON4tws7rYFkWQbjAcKaC0qp371eb9MYi9xRShVhZS5m3jF1RIM8ZubXDjSQZ8g05Np7eYG0YUEptZ8kyRAebJE7ZR3RZ0MoPjVI9ShJkj0fO41ApJTrSqmvDbnwl4g2jdda5E5hL5SKmXeFEMgXwnuICKplFQIi8rJjBaI99Rmh0OCpkyRJBvBUG9Z8SqXPOGTmjYY4Ggoh9mz3p4Bo76C4ocjZLsjqwOi+L3d8wV2vIziPmcGUS6Z/VIWjzD/zoQ0LRITiNkCf1PJ5H47yPoTB5ILOM4CxFlH9owl2SkayLBszs6vRmyhuLVhrDaD24EQu+IooEZ0KIT7gjBKIlFI53n6hE7roVts2huYsIUTxjobzba8tva3ZgRBYi6g53wdkqrg1sGb1w4xAoGwTueAqojYgmN4M6okWw1bcmmIHxVHL6akRBS3lCF30XK4iOHVsvYjWWpwLIUSRDr460rU9R10ZmRbFBVbH/pcOieQdxpx15Pr6+qOnPZ+yQym1a7pdLQgfjYwjVJh530yGLfqtifPNMLa8vHxkq/JWIB0T07ywpDnLspGr7VBKbVXCDbOIq5o7CTN5UX1gkUAKYdAq89NlBTybpukrrWSoFWCt0xUUiPG0DqlvHjEAY2ADPVbX8ZhCAynyQ1d85ENrZepEB1FYIER0JIQY6JCB2mF46hw2bUAFZQQGRFHUr463YCfPcwxkGAfWF8VScCB6zthyTXV6KhwwM5jrrFb1tieUalXPRQHD3H1i03xf/+QLr+CM2AxAIUSXipYlTVOz46J5ROFBgNTA/dLVv1jGYTTWG0ofCRP3HwMQY9BbM5zlef6nE4p7kF8TNuhGfYlcjgeztENBGTGHN/VZxvOmC9Dy/LgYqRmH5LZOdER0Ecfxut6BPb4cgTqladqH17Wnd7BtqRTBYlaJ43hkVkh5no8btjUP1v1iPG21UddAv87SMAJd0BypxD+2iNj5ovea+HtNb+M3lFIYd13/p3hF7F6A1K2YdfnQhOYZiJfre37gQRgJgfEZSAivznPmf8lIdX06j/NC/Lbcn3knxBBvD32m98/Q0AYs6vx/mp7mUeb5HL0AAAAASUVORK5CYII=);
}

.search-icon {
    height: 20px;
    width: 20px;
    margin-top: 1px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAADiUlEQVRoQ+2ZzU0bQRSA39OuxN7iVBBSQfAF8eYCVBCoIFABpIJABYEKAhXEqQC4zCAupoOQCgInQDJ66Fm71nqY9c7MrpPFYm6w8/fN+39GWJCBC8IBbyAiyeFw2Ht8fFxn5i0AWGbmHiKuyDdmvkbEWwC4QcTB0tLSRb/fl7/nMqIkYozZYOY9RBQA78HMgzRND1dXV6+9F3lODAIxxiwDwDcA2PHcv2raCQAcEtFNw30my71BtNby+j8QsdfG4cwsararlBq0sZ8XyOXl5R4zH7kOZOY/ADAQO0iS5LZQm6urq5Wnp6debj9biPjBtR4R99fW1o6bwtSCaK33EfG746ALADggonOfS4hdyXwAWLfnM/NXpZTzoXz2ljkzQUSdEPFnmwdXPQwzbzdRs0oQMWxmHlo2cZckyUZTr5OrnUjyXfFIYjOI2I91ALNAxLN8KUsjSZJ+U4hivxxmaEn7lIiiPKITJNfns/IhbeixraIuNYt9LCeI1lq80OfSwRdEJMba+jDGiIpNHAAz/1JKBQVap7FL2vHw8PDXuvGmr3cKJXVJn4hqval9zosFtqeSOKGUkog+t6G1lnxsEmdiPNgLEGPMlJEz87FSan9uFACgtT5CxL3SGcFG7wKZ0lkAmJtaFRd3qFewTbpUS2LHOBWXEetFQiRou2IpAZRS/ZA9XBLh8gYxhhdygZJUGp270CBS9Hz6z6oV7CkX19i74H5jXH5tQJTmARF9jDFg3zXGmN/SvCjmtxIQu5CiZFn2PrTj4ps0nhPRpu8Lh8wzxkiWXU5Ig6O6M2mUfy5MGp/DzLWwqnisqBS+UiI5iBifxJSpcjRN082mVaKkJKPR6Mwuo7MsWw61jUKNo5oPTVo4Va0l6UIqpbZD7Ks8t7aAmdEOkixZuoUh7SDpUs6qNE+IaDcGphZENp0BI59v5DWlSZem6V25QTcajUQtpaU0bnJ7XjAKxgskh5ELiQOY2IznxWKmBcN4g5QcgHQLp9pEETc9zdfM2icIJgikuHDudQ6sTosPjwDIBcd2Zed1jg28YaJAigMlnbm/v98o2YB06osS4E7cd/6Dz3WWZQOXa20LphGIjwh85rQB0wmQNtSsMyBNYToF0gSmcyCxMJ0E8YTZJSIJ0OPRWZAamBfFV6dBKmCcFWTnQSyYyjL4VYDkMDtlm7AD7asBqcsQ3kDqXuhff38G7UP8QqvFZwkAAAAASUVORK5CYII=);
}

.invest-icon {
    height: 22px;
    width: 22px;
    margin-top: 1px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAFKElEQVRoQ91aUVIbORCVbKo887W5wcIJFv9QaH4IJ4hzgsAJYk4QcoLACUJOEHKC2D8SxQ/kBMueYOHLrgLcW0/bcinjGY9GGmepnT9qZKmfuvv16x6k+J88chM4jDHbRPRJSjkiolshxMeiKC43cZbbs3MgDOJGSvnKN5yIzoqiONkUmE6BMIivUsrdKoOJ6FJKCc/AY1jzGoCJ6KQoirMUkFFAjDGvhRDvYJA7HIaVvRBo2DTLstFwOLwPXF+5LAqI1nokpfyacjD/9otS6qiDfUQUEBxsjLlgr1TaQUR/4YWU8veaMFuGEy4mz/NJileigdSBAQAp5ZFSasJrcOOI/98Y0IMQYuS9/0xEP/6THPFvWGs9llKesqHLeL+5ubGshVv2QnHa7/fHe3t7t3g/m82+SykflFLIuaQnySP+yTDMhQbIgIiQQ3d5nh+WQ+b6+nr3+fkZ70EWO0qpuyQUCOGUDYwxCBtSSn3BPgAzn8/fCyHgIfugIPZ6vdP9/f1voGfOK/d+2oU3bC6mAOEcQOX+I2afLuqHO7cLIPDK5xggQohDl/RXV1dviGiEIunVJxDGJMuy8yZGSwbCXkExc6wUjEkpZc83xvzpF9caun67Tq91BQQ3dxCM4N+FYCvLbMYY5MyYiAj5NBgMbM7NZjNIGHjpHRFtHgizEGpFKBgYeuqzFRPFWAjxgS8EBfdjKKMleYRZSLjDAOjp6elISok4LxPA1IlGt55p+j2EpGM+3hMgDojoXkp5opTC32ufKCCOZokINwgZAm9ANwXVAzYWN+/rLPz22Kv4CDfnnSUp1KFpDaRUzH7aFzfe6/Umi8XitiiKqf9Sa33Q6/V2F4sFPFYp87nuXOZ5fgyWYpUN2b+UNJ0A4QIYS7VN0eG/v+v3+28hZUJ/FOyRXwjCKQJQ+nFoixwE5FeDKHkBeZOe7IlNFOjz1BgTU2eWntna2jpsCrO1HmE6hdT+aZAQGrdcB5KAMAGAhofrWHEtEGPMd9Y+LWy3S1EzMDWxIyAeSqBvAd22ljJ8+EQpddiatRLyYtmHo948Pj5uu7Bg6kaYRYFZp5ZrPaK1/rttSKHNLYpimwsmGifb+fkVmjvKT21d7PbJ83ynSglXAon1BhGdF0UxrjO23+8P4R1jTJRaZvCWQMoXUQekUVbX3KhjKV9e+Ettf8HSZDkTa+mdO6XUTiMQjuOblps7qrQeYWkBotjI4zzrb77ikZQYxrAhy7Kh00kQlVLKNxtAsxJeK0BSihcbfJFl2YlLSB77uAYJTVIUY5UuY2VoUeURTNJr1WnI7TJL2YH1YDCY+qDm8zkkP+bGKU8zEGMMpZxQ89sJM5otkFprgEwKOdfvu/OqQmsTQBwZ2L67CzJoBKK1vqsbPAd46pCIXtVN6onoW1EUI+4QQfFRjyu8a1krMdltS7rmMmxsd+CRoByJltzctx95LWqZoSxQHv+4fjzGK81AEuuI8GQIPq/hg5BrAS4gwztoDaDdVj7VVSU7pENK/N7z0Pq8fNXGGAzaztqK0QqXrUzw67RWSng5hlrOpLTWtnZ0AADesITRqLWwILVv8A5JbnVLBuNr125Vp1jbj8RK+ZjMbfGb2kFEU6tb/v7X4sxOlz4Q0VHSNJ77bSRokqSIhYWckFKOm8axQXMtGMHVGB5CokV9oWoB5ocQArrMUnbI74KBVFApPni6/3bwv8qGflrwZ8MQlWC5WzfEDjF+rURpu8FLWR/tkZcCoFbGvzQDQ+35B2Z931ELZu3XAAAAAElFTkSuQmCC);
}

.settings-icon {
    height: 26px;
    width: 26px;
    margin-top: -2px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAEqklEQVR4Xu2cS04cMRCGy4KFexVyA3ICmA3C3gROQHKChBMEThA4QeAEIScInIDJpo3YDHOCcIPAChYgRzXplloTRjRdblcNU5aQWEy53P/nqvazDWhhVcCwelfnoACYO4ECUADMCjC71whQAMwKMLvXCFAAzAowu9cIUADMCjC71whQAMwKMLvXCFAAzAowu9cIUADtFbi4uNiJMX4AgNUY44oxZh2tY4xXxpgbALg2xpxubm6eta+V95fiIyCEsAoAX1F4Y8xKG7lijDcIwlq7PxgMEIzYIhpACAGF32sr/LTKFYgj59yhVAIiAYxGo5W7u7vzOsVQxcMUVRTFtsRoEAfg8vJy/eHhAcVvlW7awsFoWF5e3t7Y2Lhqa5Pjd6IAVD3/d2rxayERQlEU7yRFghgAqdPOrN6L6ch7P8jRu9v4EAMghHCAo502jU7wm0PnHPpjLyIA4FAzxjjqK/U8NTqSkoqkADgBgE+Zu+MP59znzD7/cycCQFmWf3L1/uYL2Xv/duEBlGWJM9yfHELEGD967085fNc+2SMghMCRfibPH2M89t7vLTqAIQC8ZxLhl3Nui8n3xC17BJRliaOfyapm7iJhTsAOIIQQcwvf9OecY9WA1TkKoQA4u98/ALg4tsbUjLFzjiX9SRoF6UuYqfdN3OowlFN9ANCJGDOAKgpw3/ZN5qbcOueSbvp0aT/7KIgxDeliXN1jcDPm/v7+OmMU3FprVyXsjImIgCoKdEOmSw5LaZNpTsA+9m9qJiYCsFEZUpGY1CNmIjYdQXgs5fHxESdnqUdFt0tLS1t6LKVFzqoiASGkWqIYW2u3JLx0px9fVAqablx1UgI3TLpGwy0A4NFEEScgnup7ogE03gtHAICnotuCQOHxcO6exF4v9iX8XHaqli0mx9MBAGexdYoaA8ANbrAAwJB7n/e555hbAC95sHn5rfgUNC9Cdm2nAuiqXCI7BZBIyK7VKICuyiWyUwCJhOxajQLoqlwiOwWQSMiu1YgFUN2YWTPG1EcHm0cIZx0nxPWjukz+jzEOi6IYS50RiwFQLcDtAACKi384201ZcMcNoQyttWdSgLACaIiOFyVyH5JFGCfcMFgAdLn9njIUmnXVt+oBAO+NYZRkLVkB1MIDAPvVoBkq412FrCCyAKhSzRcAELsuPwXkwFp7nOM90TuAEALm9u89vFT7ThWYjnadc82RVXKfvQLAj23MUa+fJe5Bnx/76AVAlXK+Cc71L+3JOFrq5dM3yQHk+uTASxWk/r6vL64kBfBaxa/h9QEhGYDXLn5fEJIBCCHgSEfq+J6agabtT5xzuykqTQKgLEv8rBi+dBemxBj3vfd4XIZUyAByf+mE9LQJjasljAF1+SIFgHOGhbSEUpKqGjrntik1pADAetGa8vApbKkXvRUAkYICIApINVcAVAWJ9gqAKCDVXAFQFSTaKwCigFRzBUBVkGjPDoDY/oU3J88DFl5BogAKgCgg1VwBUBUk2isAooBUcwVAVZBorwCIAlLNFQBVQaK9AiAKSDVXAFQFifYKgCgg1VwBUBUk2isAooBUcwVAVZBorwCIAlLNFQBVQaL9X2jGvnB39lNNAAAAAElFTkSuQmCC);
}

.exit-icon {
    height: 18px;
    width: 18px;
    margin-top: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAACTklEQVRoQ+2a0XHbMAxAgZM+qK8mE6SZoPGP78AvZ4JkhHaCJhO0maDuBu0G2SD5oj7tbOBO0PjL/vEhB591p3NtB6JYBUnkL/kOAvgAkIRAIgBACOEHIl4CwEf5b+A3A4BfRHSjHQuGEMaI+FX7QsdyN0T0XWNTQP4i4pFG+AVkZkR0qrGLZVmyRvClZIgINbZ7EI2XUsj0EUnhxZQ6+oik9GYKXX1EUnixjQ5m/omItxsdI/XObmlDFAjv/VWMI0xtiFmWDYbD4VSKWClgi6L4MhgMHjVgpkAA4JSIZmVZTgHgEzNPi6I418CYAmHma+/9eDKZHC2Xy/smMNZAHvM8P5f0agpjCkTmAjNHwZgDiYUxCRIDYxakgvHeH8vzc3PGNIgA1GutQzCtQGS5lHJC1n7NppVCZh9MNEi15qcYXFMdu2CiQZxzx7LjhhAmiHjWdDAp5aUCaA1SlRMpBxah6yEaBADUzbOIgR18RVJrsVjcbTLhwTk3agMixqQL+Lvryb4NISneFiS1s//Rt7387oKQl6yDzIlo3c7dlU718t4yyDzLslFVCe+LRBVCqyCNIKymVmMIiyBREOZA6p+6z82J7eXN2hxZNx9qZc96s3t1zYdaO0iOA8+cc5caCIup9TYadJuvwjEA3OZ5Pl+tVhfak11rc+RgiXKoHupB/nu1uDHQn4905WmtnT4iWk91JfcuIyInQh+68nATO8z8x3uvunol+4g0EL41MdChrLpTs755IzDM/BkRTzoc5F5TEglElItnqrtaougJEgQIkWKDKc4AAAAASUVORK5CYII=);
}



/* ACTIVE-COLOR: #000 */

.rental-icon.active {
    height: 18px;
    width: 18px;
    margin-top: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAADpUlEQVRoQ+2ZjZHMQRDF+yJwIkAERwSIABE4ESACRIAIuAgQASI4IkAEiID6bU1f9c1OT8/8d2Z3a5mqq6uzszP9+vXrj3EkB7KODgSHzAZyU0TeiMgvEXkqIl9mOW4mkGci8twYDhj+fj0DzAwgygK/S+uTiDwSke8jAY0GkrPg2TqcnVFArict3On0Muw8SBrq/Orl7SOAPE6xf+xY8jn9+23nc9gh1N5vgmQTIBELvxPAV8nAJ+nvK47BAAEQwLrXUiCnIvJSRDwWvooIe/J0SwJ4KyIno9npBYLh1IX7FZe9MGlXMxjb8bYCIw2TGLwFWOpOMzs9QDAeEB4LPxJANRbtaFipwYSX1hFAEk7XHDSkZ8CTEMLVAqSFBYzDy3gw0o6tI5zN9wDtLZwBy1V2IiCk03cVFhA0TKnXIu2osdqyEEIs7oEdLxGE7HhA8BQxTCh460MSNEa1sFY6x2YqzgDYvcqdsAc7a6sEBO+gBUKktGABz2vej7QTxXdeRzgPQB47aNAmjtX5FkgLCxQ3QEB1y/4IhP3cagEnAsYronzvEjsWyLmIeI1eXtwi1noA2L25FqIiijbv5oz8cW6nuEG3dqutjaEep87yzi9da70NO4SxV0RX51tGSheVipvHmsfCEiCclWvBK6JrQPiios5bjFJxq4UPxREvQr0mBcIRJxAuXhEsnZkXUdviYOfKsVEdiYpbfjFaQrR2MvSMo1drXeEwVqsjD4P2vGQE/ZG2JTDAGZrGYZwOQLUW9Vv5+TqMnZWqvAekR5h64QXNCYzXdjBIabhhnFcvamyt2T0SiCYGGPhWsQLjr6bPiXdY611TgainCamPgWWEH4DY2zseF7U9khHVBxUfPfRkpr1iBBHSvrAIL8S8JGxaQE0NLQy4lY23sENXoCE0iqXpQIh7tOJNdRQvWONnSbZStqYD0YsAQkZiZilNdlH/FIXX1oBYQxC+tir6xsXnmySFnQCxoKjqZDctiIQYQ1zv2jkQNfiGGc5+9qIo9Ygj64iGDYKOhGzHgyXt0FRG9HCqdu15BwK0C0Ane8eINQ5xexMdzSV1hWy2lxohO1EQNSMxEGGoFkGdVbTPgg3eCbzXmpp0poYWF7e+qAOCh78lDeP0plE9SMjgdXqv/L/XSAQ8wMGW94bcksSmM1IyYunjw05D66CBtIRK756dhFavkS37/wNp8dI29/x7jNjn0216uuUu+352sT96Mm05eC/2HAyQv5um3zNy5bn6AAAAAElFTkSuQmCC);
}

.search-icon.active {
    height: 20px;
    width: 20px;
    margin-top: 1px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC50lEQVRoQ+2a/5VNMRDHv1uBVQFbASpABagAFaACVIAKUAEqWFvBbgeoABVwPk6yJ7JJ3kxyL3n37Jzz/njn3ZvJZzK/MrsH2ogcbIRDlyDhJA8l3ZZ0X9J1SXy/GX47k/RD0ldJHyWdhO+rOEHvidyR9CQAeDYG0EtJQC4qXhCs/lzSo8FdvAtAnNYi4gHBfd4G91lCOW73OLjd8HpWENzodUXbt7AZ3IbNRbchVogZDMDnWuX9p5LejJJYQFD0qqCI4H0h6bNxE8QVz5MccnnWMJRp+V0gWPLDwoprhnkw4mYtEAL7NIuJn5Kw7GjWwe04ySuJkXDLWyFdm04hfagFQmZ5mK2IolGIuCQwGCqV970ZsQaC1Y8zJcN+XDBzyc26jFUDIQPdSxQT2MCtIbhYmgA+dRTaYq9Fyvye7fiuIzt5YUunvysJXdBReiHPVNQJAn9NocKndcadwUogeZBTrPDlNYViS9GN4g76Ekjus2u6Vdx47l7umCyBkBJjK46iriziPL48FZPi0WuWEsiv7G134Jm1//3gkN5Ng3CsNxJj/Q/XcmfKTQf7DOnXnfItBZFiddQZwNbXvmRFd5GCOEOLctU7cbE2jRRJCuMaQpedNqTuqs6mNt/GA7n2xarU9Xa18K0T4Tc6XmpKfh3FxUZvibQkuBTxGIVrNDq58rplV/tRGz6MjHBqoyUuc2SrLtkFwqK1qQcJgPGnZxzElLJ108SdGdq5xQLSguG3OKTGorhHOqDDLeOAzno564KxgrBhNoSSNGbcljO+4IbxgMQEwLQwHxMZ93f+GLUCaa3jgvGCxJ2QdQBKJy0WGADYYIyrUopP1zHD9IJEZaRPgjf9Q0+8AsR4IWb4xCF3DrwIzCiI5RQszwzDzAIC7BDMTCBDMLOBdMPMCNIFMyuIBYZWhrj6IzODtGAuXL5mBynBFG+Q+wCSwlSvwfsCAgz/pHAeE3mV3SeQZodwCWJpoP7lM78BA82jMzUV/gIAAAAASUVORK5CYII=);
}

.invest-icon.active {
    height: 22px;
    width: 22px;
    margin-top: 1px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAEIElEQVRoQ+2aj5ENQRDG+yIgA0RwRIAIEAEiQASIABEgAkTgRIAIEAEioH5qPtVvd2dntnv2XZXSVVfu3c6b6a+//jvrxP4ROdkJx2Uze2Zmt83sk5k9NbO3O531Z9s9gADio5ldnCj+3Mwe7QVmNBBAvDGzqxWFYYUf1rHmRgEMQICGJQoEBe4WhXQ4ik1Z6FHsQ3HBHz2La2uiQPB9LJ+V12Z2L7tJNkZeFVZqenwrDy5VFnh3wjBnZhZmJcqIdFsCAwCsjGIIv+P/F8rnn8WV9PylmX0+rxjxRn5oZk+Kot7fFS9YWa7Ic9aTknn+3swARsylJMuIPxzF5BooRgx9NbObCy5DYuA52etKWXeuQHCbX2ZG0CKAeVAYkmJYH8beFcXJdnxGYCjNRjbYvaKnQXOm64fOHeFasELARgS3U9DfKrEEQ7gcwjN+XrQy2gggHEhsKCttAaTzv0yK69Ied9b6tVFAsNr1LQhKtlJmI2bIZsQbvyvmYIeMR1wdBQhZiFrRCwZFUZisJgEUYB6XP1Cj6Jr9mqqtsozIl3UYgIgZLDlNAGQoNY1azzqyHH8XC+wJCIyCy5IQ+LwqUSBKs1gQgQ0U6bJeiQcs7/ssvnvfBT+MiR2fFBYBRYD4YjbdFMsSL9QOGPCChcVYrc1nPXsACDZgjM/qxYa5VibVtrzDP4cdghuDdMkWRo4FQorDCMx0jci9QI4NwrMAmCHBnhmiSJ8EbaTOeGYI9lU3azFCUNJqR0ZYFBkBhH1ws2trWbEFBBCR7pSMRUqWf1MbSNW4aKSVAQyswszm9BuNCz+HwyQg5BYwjEJRMNVueY2R7wGXYsxFcQAwOIlNX6Fhhsu7iLAPg9hstq8BibJBu42iNWXxc9iJdss+7g4MUQPS01YvWVTB7dsLv07zBYZSn7aVGYolrDSB4MdceUZEjOBSJIq9RMz+3X+JkYwPYy0OUZ/EXkx+o0XMrwLJFC82pgqTXRSQBL4GJIprNGN5Y8wuLZYYwa3WutMe6wJCsweHelDUFya+jHQBYdwcLbpAUIHk36zLHZCwxMgeQGQYzd0jkkETCAFbu3huMUULoWK4tJZLOuKE1EuKj4oK727BrpG0Zgz5dpaRrhjJZC31WRpRpxlKQGsFs5ehLiCZOoIiKla4D26kEYC0DFPZ0YAzZs3jUrBn/ZdUi8Wp8lMh7ZJ+o/ON9pvd4Nd6rYx76TDf8ap2ZAGwtxLGgZFqQLJzgw4ZNSFqP14Kodvs/mxtHom28r0BG1lXvYhojbrT93+Rw0d8BybQpXo11AKCEgQ/Pp5tKaKAiAky6ep1bA8QKQAgrEJKjb6h6gXDW16sr5Td/N4WINPNKHr63w7+pqX31YK/G9Y7dsZgvcFqKu8XZIBsOmjvxf+B7G3hrfv/Bsj05zPPcbN3AAAAAElFTkSuQmCC);
}

.settings-icon.active {
    height: 26px;
    width: 26px;
    margin-top: -2px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAEBUlEQVR4Xu2cjZFNQRCFeyNABkSACBABIkAEiAARIAJEsESACBABGSAC6tu9U3XrsbV35+/02z1TtbVbtW+m7ztfd8/PnZmDcJEqcCC1buNhAGInMAADECsgNu8IMACxAmLzjgADECsgNu8IMACxAmLzjgADECsgNu8IMACxAmLzjgADOJMCdyPiXkRcjYjLEXFjqf01In5FxI+IeB8RH87UqvDD+xABiP1sER7RtxRgAOLpAmZLHclnsgNA+CeLt9cIBIhXEfGipvKMOlkB4OkfVymmVQtS1J2M0ZARAHkd8bemm61wiAYgACNNyQYA0b8PEL8IDoRrmSIhE4DeaeckLycCbmYJgUwAni+jnRna0CljT16yAGCo+WVg6tkVOk0qygLgbUQ8mOyO7yLi4WSb/5jLAuDnRO9fd8hXDOB4aeFQJMT9ZcYsMh8pdsYp0k8R/PUy077QAD5FxC2RAp8j4rbI9pHZDH0Ao5+yqjlbC/mcIAOAP7NV37En1UBqfBHCAMQeSBq4LnqGb8L0l6YPcCcs8r5i1sNQMQBPxMQAMM/i2KXJz/FbsPyRdi1IkYa8GLdyB17GsKVkVhTg/SyBE3nSkmEeUATwCxmpKxwbnzEnkI/91zpnigCea3QqSpN6CoRsAHguFuaYnPXuDxCflU9vS9mQ6ogEIPRaoiDtIL6809397hkjYP2MdMxsTayNBryerYkpdkD8z/GyAyj9AiIyY94KAuHZnAu8dF6fuRM+LTsBYb09vaQoUgxCk99JXYi/F2UfImAvhKx9SAOoVa5TPQPoJGRtMwZQq1ynegbQScjaZgygVrlO9Qygk5C1zRhArXKd6mUGwHoQE62ydXC9hfCk7YRMwkopf/O7TNQ6ydavmUwAEJyD2IjLD2+sehbeuAGDHw5yp1iiUAMoonNQYvYmWUDwLloKQwWg5vR7z2hYt1VO1XNujCiZWmYDKMLLjwadoDIRMRXELACkmseZ1+V3gPD+gMMbw/uJGQDI7W8GdKqjUwXp6NHSaQ+zNRoAl22kfRu1UVWef9hlH6MAkHJeZjgGulHk0z5G3zDk6psRAGZdOXCaaL3/P+TGld4Azqv4BWZ3CD0BnHfxh0DoCYCRTtbxfe90RJ/ACKm59ALA9g863YtU6JTZLtNUegCYfdNJ0xfuWJlJGvcONS1f9ADA9WKzF9I66tjUFAt6XINWXXoAUJ/zrf7ynSo2adhUefkCBtBA0gAaxFuqNmnYVNkRcKRAk4ZNlQ3AANoTSHsLTU7cVNkR4Aho99/2FpqcuKly+7O7BQMQ+4ABGIBYAbF5R4ABiBUQm3cEGIBYAbF5R4ABiBUQm3cEGIBYAbF5R4ABiBUQm3cEGIBYAbH5v3KJjGFVb97uAAAAAElFTkSuQmCC);
}