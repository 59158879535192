.invest-pic {
  margin-left: 8px;
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  top: 12px;
  position: relative;
}

.invest-pic-border {
  display: inline-block;
}

.header-summary-content {
  color: black;
  display: inline-block;
  margin-left: 16px;
  margin-top: 8px;
}

.header-summary-content input {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 216px;
}

.invest-expenses {
  padding-left: 16px;
  max-width: 55%;
  display: inline-grid;
}

.invest-expenses input {
  border: 0;
  outline: 0;
  background-color: transparent;
  border-bottom: 1px solid #c7c7c7;
  ;
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: bold;
  color: #525865;
}

.invest-expenses-label {
  display: inline-grid;
  margin-left: 16px;
}

.invest-expenses-label label {
  margin-bottom: 0;
  margin-top: 3px;
}